import React from "react";
import { Link } from "gatsby";
import { Chevron } from "./Chevron";
function LinkId({ classId, goto, value }) {
  return (
    <>
      <div className={classId}>
        <Link to={`/${goto}`} aria-label={`navigation to ${value} page`}>
          {value} <Chevron />
        </Link>
      </div>
    </>
  );
}

export { LinkId };
