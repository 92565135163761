import React from "react";
import { Image } from "../Image";
import { useStaticQuery, graphql } from "gatsby";
function Pattern() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulHeader {
        nodes {
          pattern {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  `);
  const {
    allContentfulHeader: {
      nodes: [{ pattern }],
    },
  } = data;

  return (
    <>
      <div className="pattern--container">
        <Image classId="pattern" image={pattern}  alt='pattern'/>
        <Image classId="pattern" image={pattern}  alt='pattern'/>
      </div>
    </>
  );
}

export { Pattern };
