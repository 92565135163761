import React from "react";
import { Link } from "gatsby";
function FooterSection2() {
  return (
    <>
      <div className="footer--section">
        <ul>
          <li>
            <Link to="/" aria-label="navigate to home">
              Home
            </Link>
          </li>
          <li>
            <Link to="/services" aria-label="navigate to home">
              Services
            </Link>
          </li>
          <li>
            <Link to="/gallery" aria-label="navigate to gallery">
              Gallery
            </Link>
          </li>
          <li>
            <Link to="/route" aria-label="navigate to route">
              Route
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export { FooterSection2 };
