import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

function Image({ classId, image, alt }) {
  const img = getImage(image);

  return (
    <>
      <div className={classId}>
        <GatsbyImage image={img} alt={alt} />
      </div>
    </>
  );
}

export { Image };
