import React from "react";

function Chevron() {
  return (
    <>
      <svg
        width={17}
        height={17}
        viewBox="0 0 17 17"
        className='chev'
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M8.50834 0.523306C4.09008 0.507569 0.495635 4.0765 0.479899 8.49476C0.464162 12.913 4.0331 16.5075 8.45136 16.5232C12.8696 16.5389 16.4641 12.97 16.4798 8.55175C16.4955 4.13349 12.9266 0.539042 8.50834 0.523306ZM7.47835 13.0239L6.73383 12.2766L10.4945 8.53043L6.76061 4.7576L7.51044 4.01555L11.9839 8.53574L7.47835 13.0239Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width={16}
              height={16}
              fill="white"
              transform="translate(0.508392 0.494812) rotate(0.20407)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}

export { Chevron };
