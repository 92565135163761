import React from "react";
import { LinkId } from "../Link";
import { motion } from "framer-motion";
import { textAni, heroTextAnimation } from "../animation";
function HeroText({ title, body, goto, value, id }) {
  return (
    <>
      <motion.div
        className="hero--text"
        variants={textAni}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <motion.h1 variants={heroTextAnimation}>{title}</motion.h1>
        <motion.p variants={heroTextAnimation}>{body}</motion.p>
        <motion.div className="link--to--nextPage" variants={heroTextAnimation}>
          <LinkId
            goto={goto}
            value={value}
            classId={id}
         
          />
        </motion.div>
      </motion.div>
    </>
  );
}

export { HeroText };
