import React from "react";

function ContactDetails({ email, doctor, clinic }) {
  return (
    <>
      <div className="contact--details">
        <div className="email">
          <p>
            Email:{" "}
            <a href={`mailto:${email}`} aria-label="email">
              {email}
            </a>
          </p>
        </div>
        <div className="doctor--number">
          <p>
            Doctor:{" "}
            <a href={`tel:+91${doctor}`} aria-label="clinic mobile number">
              {doctor}
            </a>
          </p>
        </div>
        <div className="clinic--number">
          <p>
            Clinic:{" "}
            <a href={`tel:+91${clinic}`} aria-label="clinic mobile number">
              {clinic}
            </a>
          </p>
        </div>
        
      </div>
    </>
  );
}

export { ContactDetails };
