import React from "react";

function Attribution() {
  return (
    <>
      <div className="attribution">
        <p> Teeth Illustrations</p>
        <a href="http://www.freepik.com">Designed by pch.vector / Freepik</a>
      </div>
    </>
  );
}

export { Attribution };
