import React, { useState } from "react";
import { ThemeToggler } from "gatsby-plugin-dark-mode";
import { StaticImage } from "gatsby-plugin-image";
import { AnimatePresence, motion } from "framer-motion";
function Theme() {
  const [Theme, setTheme] = useState({ theme: "light" });
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          className="toggle--theme"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 1, duration: 1.5 }}
        >
          <ThemeToggler>
            {({ theme, toggleTheme }) => (
              <label className="toggle--area">
                <input
                  type="checkbox"
                  onChange={(e) => {
                    toggleTheme(e.target.checked ? "dark" : "light");
                    e.target.checked
                      ? setTheme({ theme: "dark" })
                      : setTheme({ theme: "light" });
                  }}
                  checked={theme === "dark"}
                  style={{ display: "none" }}
                />
                {Theme.theme === "light" ? (
                  <StaticImage
                    src="../images/dark.png"
                    alt="A dinosaur"
                    placeholder="blurred"
                    width={48}
                    height={48}
                  />
                ) : (
                  <StaticImage
                    src="../images/light.png"
                    alt="A dinosaur"
                    placeholder="blurred"
                    width={48}
                    height={48}
                  />
                )}
              </label>
            )}
          </ThemeToggler>
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export { Theme };
