import React from "react";
import { FooterTop } from "./FooterTop";
import { FooterSection2 } from "./FooterSection2";
import { ContactDetails } from "./ContactDetails";
import {Attribution} from './Attribution';
import { useStaticQuery, graphql } from "gatsby";
function Footer() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFooter {
        nodes {
          address
          email
          clinic
          doctor
          attribution
        }
      }
    }
  `);

  const {
    allContentfulFooter: {
      nodes: [{ address, email, clinic, doctor, attribution }],
    },
  } = data;

  return (
    <>
      <footer>
        <FooterTop body={address} />
        <FooterSection2 />
        <ContactDetails
          email={email}
          clinic={clinic}
          doctor={doctor}
          attribution={attribution}
          body={address}
        />
        <Attribution/>

        <div className="copyrights">
          <p>&copy; All rights reserved 2021</p>
        </div>
      </footer>
    </>
  );
}

export { Footer };
