import React from "react";
import { NavComp } from "./NavComp";
import { Back } from "./Back";
import { AnimatePresence, motion } from "framer-motion";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
function MobileNav({ setNav }) {
  return (
    <>
      <motion.nav
        className="mobile--nav"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          duration: 0.8,
          ease: "easeInOut",
        }}
      >
        <motion.div className="mobile--nav--wrapper">
          <motion.div className="mobile--nav--header">
            <AnimatePresence exitBeforeEnter>
              <motion.div
                className="logo--image"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ delay: 0.8, duration: 1.1 }}
              >
                <Link to="/" aria-label="navigate to home">
                  <StaticImage
                    src="../images/logo.png"
                    alt="logo"
                    placeholder="blurred"
                    layout="fixed"
                    width={24}
                    height={24}
                  />
                  <p>Dental care</p>
                </Link>
              </motion.div>
            </AnimatePresence>

            <motion.button
              aria-label="close"
              id="back--button--two"
              onClick={() => {
                setNav({ state: false });
              }}
            >
              <Back />
            </motion.button>
          </motion.div>
          <NavComp setNav={setNav} />
        </motion.div>
      </motion.nav>
    </>
  );
}

export { MobileNav };
