import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";
import { heroImage } from "../animation";
function HeroImage({ image, classId, alt }) {
  const img = getImage(image);
  return (
    <>
      <motion.div
        className={classId}
        variants={heroImage}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <GatsbyImage image={img} alt={alt} />
      </motion.div>
    </>
  );
}

export { HeroImage };
