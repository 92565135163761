import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import { container, item } from "./animation";
function NavComp({ setNav }) {
  const data = useStaticQuery(graphql`
    query {
      allContentfulMobNav {
        nodes {
          id
          slug
          name
        }
      }
    }
  `);

  const {
    allContentfulMobNav: { nodes },
  } = data;
  return (
    <>
      <motion.ul
        variants={container}
        initial="hidden"
        animate="visible"
        transition={{ delay: 0.3, duration: 1.2 }}
      >
        {nodes.map((n) => {
          return (
            <AnimatePresence exitBeforeEnter>
              <motion.li key={n.id} variants={item} whileHover={{ x: 20 }}>
                <Link to={n.slug} aria-label={`navigate to ${n.name} page`}>
                  {n.name}
                </Link>
              </motion.li>
            </AnimatePresence>
          );
        })}
      </motion.ul>
    </>
  );
}

export { NavComp };
