import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
function FooterTop({ body }) {
  return (
    <>
      <div className="footer--top">
        <div className="logo--image">
          <Link to="/" aria-label='navigate to home'>
            <StaticImage
              src="../images/loaderLogo.png"
              alt="logo"
              placeholder="blurred"
              width={48}
              height={48}
            />
            <p>Dental care</p>
          </Link>
        </div>
        <p>{body}</p>

       
      </div>
    </>
  );
}

export { FooterTop };
