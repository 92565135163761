import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import { AnimatePresence, motion } from "framer-motion";
function BackToTop({ id }) {
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          className="button--wrapper"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.2, duration: 1.5 }}
        >
          <button
            onClick={() => {
              scrollTo(`#${id}`);
            }}
            aria-label="back top"
            tabIndex="0"
          >
            <StaticImage src="../images/supertooth.png" alt="supertooth" />
          </button>
        </motion.div>
      </AnimatePresence>
    </>
  );
}

export { BackToTop };
