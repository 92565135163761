import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { HamIcon } from "./HamIcon";
import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import { MobileNav } from "./MobileNav";
import { useState } from "react";

function Nav() {
  const [Nav, setNav] = useState({ state: false });
  return (
    <>
      <nav className="small--nav">
        <div className="logo--image">
          <Link to="/" aria-label="navigate to home">
            <StaticImage
              src="../images/logo.png"
              alt="logo"
              placeholder="blurred"
              layout="fixed"
              width={24}
              height={24}
            />
            <p>Dental care</p>
          </Link>
        </div>
        <motion.button
          className="nav--button"
          aria-label="hamburger menu"
          onClick={() => {
            setNav({ state: true });
          }}
        >
          <HamIcon />
        </motion.button>

        <AnimatePresence exitBeforeEnter>
          {Nav.state && <MobileNav setNav={setNav} />}
        </AnimatePresence>
      </nav>
    </>
  );
}

export { Nav };
